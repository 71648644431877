import { render, staticRenderFns } from "./ActionLabel.vue?vue&type=template&id=72e5d41a&"
import script from "./ActionLabel.vue?vue&type=script&lang=js&"
export * from "./ActionLabel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBadge,VBtn})
