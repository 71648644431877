<template>
  <v-badge
    :color="action.typ_color"
    dot
    inline
    left
    class="mx-2"
  >
    <v-btn text class="pa-0 ma-0">{{ action.typ_label }}</v-btn>
  </v-badge>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      required: true
    }
  }
}
</script>
